import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";
import { RootState } from "dashboard/src/store";


interface AdminConsoleState {
  organizationId: string;
  orgId: string;
};

export const initialState: AdminConsoleState = {
  organizationId: undefined,
  orgId: undefined
};

const slice = createSlice({
  name: 'admin-console',
  initialState,
  reducers: {
    setOrganization: (state: AdminConsoleState, action: PayloadAction<{ organizationId: string }>) => {
      const { organizationId } = action.payload;
      state.organizationId = organizationId
    }
  }
});

export const setOrganization = (organizationId: string): Action => slice.actions.setOrganization({ organizationId })


export const reducer = slice.reducer;
export const selector = (state: RootState) => state.adminConsole
export default slice;

