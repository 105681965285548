export const APP_VERSION = process.env.NX_BUILD_ID || '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const acceptedFileFormat = 'application/pdf, image/png, image/jpeg, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const acceptedXMLFileFormat = '.xml';

export const ENL_LOGO = '/static/logo.png';
export const EORIGINAL_LOGO = '/static/images/logos/eOriginal.png';
export const TITLELOOP_LOGO = '/static/images/logos/titleLoop.png';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  ORG_STYLE: 'ORG_STYLE',
  ENL: 'eNotaryLog',
  UNICORN: 'UNICORN'
};

// add the accordions needed for eSign here
export const eSignAccordions = ['addparticipants', 'documentupload', 'transactionaddons', 'signingorder', 'enoteupload',];
// add the accordions needed for RON here
export const RONAccordions = ['addparticipants', 'documentupload', 'transaction',];

// all Accordion names are given below
// const allAccordions = ['addparticipants','documentupload','notifications','sessioncontacts','transaction','transactionaddons','organizationcollaboration','scheduling', 'signingorder','enoteupload','organynotary','orgspecificnotary','externalorgnotary','referenceid']

export const PAD = 3;
export const ENL_RED = '#ed5f42';
export const ENL_TEAL = '#507f91';
export const ENL_GREEN = '#1DE9B6';
export const CORAL = '#ED5F42';
export const PEWTER = '#507f91'
export const BACKGROUND = '#F4F6F8'
export const BORDER_GRAY = '#e0e0e0'
export const ENL_CHECK_MARK = '#3AD29F';
export const TITLE_TEXT = '#343746'
export const TIME_ZONE_12HR = '12hr';
export const TIME_ZONE_24HR = '24hr';
export const BUTTON_TEXT = '#187082';
export const BUTTON_BACKGROUND = '#E4EFF1';
export const SITEMAP = {

  dashboard: { label: 'Dashboard', path: '/app/reports/dashboard', },

  signingOrder: { label: 'Signing Orders', path: '/app/reports/signingOrder' },

  createNewOrder: { label: 'Create New Order', path: '/app/:sessionType/orders/create', },

  editExistingOrder: { label: 'Edit Order', path: '/app/:sessionType/orders/edit/:id', },

  viewExistingOrder: { label: 'Edit Order', path: '/app/:sessionType/orders/:sessionId', },

  //#region ron site-map
  ron: { label: 'Notarization', path: '/app/ron', },
  ronRoom: { label: 'NotaryRoom', path: '/enterprise/room/:id', },
  ronOrders: { label: 'Orders', path: '/app/ron/orders', },
  ronNotaryQueue: { label: 'Notary Queue', path: '/app/ron/notary-queue', },
  ronTaggingQueue: { label: 'Tagging Queue', path: '/app/ron/tagging-queue', },
  //#endregion

  //#region ron site-map
  eSign: { label: 'eSign', path: '/app/esign', },
  eSignOrders: { label: 'Orders', path: '/app/esign/orders', },
  //#endregion

  //#region templating site-map
  template: { label: 'Templates', path: '/app/templating' },
  templateManage: { label: 'Manage', path: '/app/templating/manage' },
  templateRoles: { label: 'Roles', path: '/app/templating/roles' },
  //#endregion

  allOrders: { label: 'Orders', path: '/app/esign/orders', },

  // eSignOrders: { label: 'eSign', path: '/app/esignature-dashboard', },

  notaryLoop: { label: 'Notary Loop', path: '/app/reports/signingOrder/:id', },


  // TODO: check where all we are using this sitemap
  // ronOrders: { label: 'RON Orders', path: '/app/management/documents', },

  // taggingQueue: { label: 'Tagging Queue', path: '/app/ron/tagging-queue', },

  // eSignTaggingQueue: { label: 'Tagging Queue', path: '/app/tagging-queue', },

  // TODO: fix path
  notaryQueue: { label: 'Notary Queue', path: '/app/reports/dashboard2', },

  enlOrganizations: { label: 'ENL Organizations', path: '/app/management/organizations', },

  organizationSettings: { label: 'Organization Settings', path: '/app/management/my-organization', },

  users: { label: 'Users', path: '/app/management/users', },

  usersEdit: { label: 'Users', path: '/app/management/users/undefined/edit', },

  myOrganization: { label: 'My Organization', path: '/app/management/my-organization', },

  // TODO: fix path
  calendar: { label: 'Calendar', path: '/app/reports/dashboard5', },

  profile: { label: 'Profile', path: '/app/profile', },

};

/*
This constant is used for saving the organization ID into localstorage.
This is used as a callback org id for when the user performs an Auth0 password reset.
This way they can be redirected directly into their org's login page.
*/
export const CALLBACK_ORG_ID = "callbackOrgID";
