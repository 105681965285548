import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { APP_VERSION, ENL_LOGO } from 'dashboard/src/constants';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Typography,
  Button
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'dashboard/src/components/Logo';
import { THEMES } from 'dashboard/src/constants';
import type { Theme } from 'dashboard/src/theme';
import Profile from './Profile';
import useTheme from 'dashboard/src/hooks/useTheme';
import { useEffectOnce } from 'react-use';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginRight: theme.spacing(2)
  },
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.palette.type === 'light' ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.background.paper
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64
  }
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const { logo, previewLogo, themePreviewMode, setPreviewMode, getTheme } = useTheme()
  useEffectOnce(() => {
    getTheme();
  })
  const topBarLogo = themePreviewMode ? previewLogo : logo;

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color='secondary'
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize='small'>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to='/'>
            <Logo
              className={classes.logo}
              src={topBarLogo || ENL_LOGO}
              style={{height:"43px"}}
            />
          </RouterLink>
        </Hidden>
        <Typography
          variant='caption'
          color='textSecondary'
          style={{
            display: "none",
          }}
        >
          Version
          {' '}
          {APP_VERSION}
        </Typography>
        {themePreviewMode && (
          <Box
            p={2}
          >
            <Button
              variant='contained'
              size='small'
              onClick={() => setPreviewMode(false)}
            >
              Disable Preview Mode
            </Button>
          </Box>)}
        <Box
          ml={2}
          flexGrow={1}
        />
        {/* <Search />
        <Contacts />
        <Notifications /> */}
        <Box ml={2}>
          <Profile />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => { }
};

export default TopBar;
