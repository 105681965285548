/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import './assets/css/prism.css';
import './mixins/chartjs';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import { initStore } from 'dashboard/src/store/index';
import { SettingsProvider } from 'dashboard/src/contexts/SettingsContext';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { initApollo } from 'dashboard/src/lib/apollo';
import { ApolloProvider } from '@apollo/client';
import App from 'dashboard/src/App';
import { initialize } from 'launchdarkly-js-client-sdk';


enableES5();

const history = createBrowserHistory();

const apollo = (window as unknown as any).client = initApollo();
const store = (window as unknown as any).store = initStore({
  extraArguments: apollo
});

if (process.env.NX_BUILD_ID) {
  const buildId = process.env.NX_BUILD_ID;
  (window as any).NX_BUILD_ID = buildId;
  console.info(`build id: ${buildId}`)
}


(async () => {
  const client = initialize(process.env.NX_LAUNCH_DARKLY_API_KEY, {
    key: 'anonymous'
  });
  await client.waitForInitialization();
  const { domains = [] } = client.variation('lucky-orange', {});

  const host = window.location.host;
  const found = domains.find((el) => host.includes(el));

  if (found) {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", '/lucky-orange.js');
    head.appendChild(script);

  }
})();

Promise.all([
  fetch('/api/app-url').then((resp) => resp.json()),
  fetch('/api/auth0-config').then((resp) => resp.json()),
]).then(([data, auth0Config]) => {
  (window as any).baseUrl = data.url;
  (window as any).notaryRoomUrl = data.notaryRoomUrl;
  (window as any).equipmentCheckUrl = data.equipmentCheckUrl;

  ReactDOM.render(
    <Router history={history}>
      <ApolloProvider client={apollo}>
        <Provider store={store}>
          <SettingsProvider>
            <App auth0Config={auth0Config}/>
          </SettingsProvider>
        </Provider>
      </ApolloProvider>
    </Router>,
    document.getElementById('root')
  );


})
