import { gql } from "@apollo/client";

export const UPDATE_DASHBOARD_THEME_SETTINGS = gql`
mutation updateDasbhoardTheme($organizationId: ID!, $value: JSONObject!) {
  setDashboardThemeSettings(value: $value, organizationId: $organizationId)
}
`;

export const SET_ORG_LOGO = gql`
mutation uploadLogoBase64($organizationId: ID!, $base64: String!) {
  uploadLogoBase64(organizationId: $organizationId, base64:$base64)
}
`;

export const GET_ORG_THEME = gql`
query getOrgTheme($organization_id: ID!) {
  getOrganizationInfo(id:$organization_id){
    branding {
      colors{
        primaryColor,
        background,
        secondaryColor
      },
      logo_url
    }
  }
}
`;

