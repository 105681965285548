/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  // Chip,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  Link,
  IconButton,
} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import WorkIcon from '@material-ui/icons/Work';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ApproveIcon from './ApprovalIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import ListAltIcon from '@material-ui/icons/ListAlt';
import NavItem from './NavItem';
import { APP_VERSION, ENL_LOGO } from 'dashboard/src/constants';
import useTheme from 'dashboard/src/hooks/useTheme';
import useCan from 'dashboard/src/hooks/useCan';
// import { clearSelectedSession } from 'dashboard/src/slices/esign-sessions';
import { SITEMAP } from 'dashboard/src/constants';
import { ArrowBack, Menu } from '@material-ui/icons';

// const activeColor = { color: '#ed5f42' };

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  shrinkNavBar: (active: boolean) => void;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  show?: boolean;
  info?: ReactNode;
  items?: Item[];
  title: string;
  permission: boolean;
}

interface Section {
  items: Item[];
  subheader: string;
}

function renderNavItems({
  items,
  pathname,
  depth = 0,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List
      key={`key-${pathname}-${depth}`}
      disablePadding
    >
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.permission) {

    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false
      });

      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
          })}
        </NavItem>
      );

    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />
      );
    }

    return acc;
  }

  // return the unmodified accumulator if user can't view item
  else return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile, shrinkNavBar }) => {
  const classes = useStyles();
  const { logo } = useTheme();
  const location = useLocation();
  const can = useCan();
  const flags = useFlags();
  const [openNav, setOpenNav] = useState(true)

  const handleNavChange = () => {
    setOpenNav(!openNav)
    shrinkNavBar(!openNav)

  }

  const ronMenuItems = useMemo(() => {
    const ronMenuItems = (flags.taggingRoom === false) ? [{
      title: SITEMAP.ronOrders.label,
      href: SITEMAP.ronOrders.path,
      permission: can.any('VIEW_EJOURNAL', 'VIEW_EJOURNAL_ALL')
    },
    {
      title: SITEMAP.ronNotaryQueue.label,
      href: SITEMAP.ronNotaryQueue.path,
      permission: can('VIEW_GENERAL_QUEUE'),
    }] : [{
      title: SITEMAP.ronOrders.label,
      href: SITEMAP.ronOrders.path,
      permission: can.any('VIEW_EJOURNAL', 'VIEW_EJOURNAL_ALL')
    },
    {
      title: SITEMAP.ronNotaryQueue.label,
      href: SITEMAP.ronNotaryQueue.path,
      permission: can('VIEW_GENERAL_QUEUE'),
    },
    {
      title: SITEMAP.ronTaggingQueue.label,
      href: SITEMAP.ronTaggingQueue.path,
      permission: can.any('VIEW_TAGGING_ROOM', 'ASSIGN_TAGGER'),
    }];

    return ronMenuItems;
  }, [flags.taggingRoom, can]);

  const showRonMenu = useMemo(() => {
    const enabledRonMenuItems = _.chain(ronMenuItems)
      .filter((item) => item.permission)
      .value();

    return enabledRonMenuItems.length > 0;
  }, [ronMenuItems])



  const ronMenuPerms = useMemo(() => (flags.taggingRoom === false) ?
    ['VIEW_EJOURNAL', 'VIEW_EJOURNAL_ALL', 'VIEW_GENERAL_QUEUE'] :
    ['VIEW_EJOURNAL', 'VIEW_EJOURNAL_ALL', 'VIEW_GENERAL_QUEUE', 'VIEW_TAGGING_ROOM', 'ASSIGN_TAGGER'], [flags.taggingRoom]);



  const sections: Section[] = useMemo(() => [
    {
      subheader: '',
      items: [
        {
          title: SITEMAP.dashboard.label,
          icon: DashboardIcon,
          href: SITEMAP.dashboard.path,
          permission: true,
        },
        {
          /* TODO: Use feature gates instead of feature flags(Launch darkly) */
          title: SITEMAP.signingOrder.label,
          icon: ListAltIcon,
          href: SITEMAP.signingOrder.path,
          permission: flags.signingOrderNavBar && can.any('VIEW_SIGNING_ORDERS'),
        },
        {
          title: SITEMAP.ron.label,
          icon: ApproveIcon,
          href: SITEMAP.ron.path,
          show: showRonMenu,
          permission: can.any(...ronMenuPerms),
          items: ronMenuItems,
        },
        {
          title: SITEMAP.eSign.label,
          icon: WorkIcon,
          href: SITEMAP.eSign.path,
          permission: can.any('VIEW_ESIGN_ORDERS', 'VIEW_ESIGN_ORDERS_ALL', 'VIEW_ESIGN_ORDERS_COMPLETED', 'VIEW_ESIGN_ORDERS_ORIGINAL', 'VIEW_TAGGING_ROOM_ESIGN', 'ASSIGN_TAGGER_ESIGN', 'SEND_NEW_ESIGN'),
          items: [{
            title: SITEMAP.eSignOrders.label,
            href: SITEMAP.eSignOrders.path,
            permission: can.any('VIEW_ESIGN_ORDERS', 'SEND_NEW_ESIGN'),
          }]
        },
        // {
        //   title: 'Notary Queue',
        //   icon: ReorderIcon,
        //   href: '/app/notary-queue',
        //   permission: flags.ron && can('read:notary-queue'),
        // },

        // {
        //   title: 'Tagging Queue',
        //   icon: LocalOfferIcon,
        //   href: '/app/tagging-queue',
        //   permission: can.any('VIEW_TAGGING_ROOM', 'ASSIGN_TAGGER'),

        // },
        {
          title: SITEMAP.template.label,
          icon: FileCopyIcon,
          href: SITEMAP.template.path,
          permission: can.any('VIEW_TEMPLATE', 'MANAGE_TEMPLATE_ROLES'),
          items: [
            {
              title: SITEMAP.templateManage.label,
              href: SITEMAP.templateManage.path,
              permission: can.all('VIEW_TEMPLATE'),
            },
            {
              title: SITEMAP.templateRoles.label,
              href: SITEMAP.templateRoles.path,
              permission: can.all('MANAGE_TEMPLATE_ROLES'),
            },
          ],
        },
        {
          title: SITEMAP.organizationSettings.label,
          icon: SettingsIcon,
          href: SITEMAP.organizationSettings.path,
          permission: flags.organizationSettings && can('EDIT_ORG_SETTINGS'),
        },
        // temp: placed all items under same subheader for ENL2020-1969
        {
          title: SITEMAP.users.label,
          href: SITEMAP.users.path,
          permission: flags.usersPage && can('VIEW_USER_MANAGEMENT'),
          icon: GroupIcon,
        },

      ]
    },

  ], [can, flags.organizationSettings, flags.signingOrderNavBar, flags.usersPage, ronMenuItems, ronMenuPerms, showRonMenu]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const content = (
    <Box
      height='100%'
      display='flex'
      flexDirection='column'
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Divider />
        <Box
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            padding: '12px',
            height: '25px'
          }}
        >
          <IconButton
            onClick={() => handleNavChange()}
          >
            <ArrowBack />
          </IconButton>

        </Box>
        <Box
          p={2}

        >
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: _.filter(section.items, (item) => item.show !== false),
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius='borderRadius'
            bgcolor='background.dark'
          >
            <Typography
              variant='h6'
              color='textPrimary'
            >
              <Link
                href='https://help.enotarylog.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Need Help?
              </Link>
            </Typography>
          </Box>
          {logo && (logo !== ENL_LOGO) && (
            <>
              <Box p={2}>
                <Typography
                  variant='caption'
                  color='textSecondary'
                >
                  Powered By:
                </Typography>
              </Box>
              <Box
                p={0}
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                textAlign='center'
              >
                <img
                  alt='eNotaryLog'
                  style={{ width: '75%' }}
                  src='/static/enl_stacked_smoke_logo.png'
                />
              </Box>
              <Box
                textAlign='center'
              >
                <Typography
                  variant='caption'
                  color='textSecondary'
                  style={{
                    display: "none",
                  }}
                >
                  Version
                  {' '}
                  {APP_VERSION}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          role='navigation'
          open={openMobile}
          variant='temporary'
          data-testid='navbar'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        {openNav ?
          <Drawer
            anchor='left'
            classes={{ paper: classes.desktopDrawer }}
            open={openNav}
            role='navigation'
            variant='persistent'
          >
            {content}
          </Drawer>
          :

          <IconButton
            onClick={() => handleNavChange()}
            size='medium'
            edge='end'
            style={{
              color: 'orangered',
              position: 'fixed',
              top: '80px',
              backgroundColor: 'white',
              borderRadius: 0,
              boxShadow: '4px 4px 2px lightgrey',
              padding: '3px'
            }}
          >
            <Menu
              fontSize='medium'
            />
          </IconButton>}
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  shrinkNavBar: PropTypes.func
};

export default NavBar;
