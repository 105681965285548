import { colors } from "@material-ui/core";
import { DashboardPalette } from "dashboard/src/components/WhiteLabel/DashboardStyle/DashboardStyleForm";
import { ThemeOptions } from "..";
import { softShadows } from "../shadows";

export const adaptOrgStyleToThemeOptions = (orgStyle: DashboardPalette): ThemeOptions => ({
  name:'ORG_STYLE',
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: orgStyle.secondaryTextColor || colors.blueGrey[600]
        }
      }
    }
  },
  palette: {
    type: 'light',
    action: {
      active: orgStyle.secondaryTextColor || colors.blueGrey[600]
    },
    background: {
      default: orgStyle.background || colors.common.white,
      dark: orgStyle.backgroundDark || '#f4f6f8',
      paper: orgStyle.backgroundPaper || colors.common.white
    },
    primary: {
      main: orgStyle.primaryColor || '#f44336'
    },
    secondary: {
      main: orgStyle.secondaryColor ||'#006f83'
    },
    text: {
      primary: orgStyle.textColor || colors.blueGrey[900],
      secondary: orgStyle.secondaryTextColor || colors.blueGrey[600]//Change to primary/secondary text color? or do some kinda lighting like the eq check?
    }
  },
  shadows: softShadows
})
